define("formaviva-web/router", ["exports", "ember-router-scroll", "formaviva-web/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // This path is hard coded, since mixes route is dynamically
    // configured for profile and dashboard, which leads to error
    this.route("blog", {
      path: "blog/mixes"
    });
    this.route("purchase", {
      path: "purchases/:purchase_id"
    }, function () {
      this.route("download");
    });
    this.route("profile", {
      path: ":profile_slug"
    }, function () {
      this.route("about");
      this.route("mixes");
      this.route("likes");
      this.route("following");
      this.route("followers");
      this.route("releases");
      this.route("playlists");
    }); // Route for edit profile is mounted as a separate route since the model hook
    // needs to be overridden to fetch the profile even if it is not approved.

    this.route("edit-profile", {
      path: "/:profile_slug/edit"
    });
    this.route("release", {
      path: ":profile_slug/:release_slug"
    }, function () {
      this.route("show", {
        path: "/"
      });
      this.route("edit");
    });
    this.route("release", {
      path: ":profile_slug/release"
    }, function () {
      this.route("new");
    });
    this.route("track", {
      path: ":profile_slug/:track_slug"
    }, function () {
      this.route("show", {
        path: "/"
      });
      this.route("edit");
      this.route("analytics");
    });
    this.route("playlist", {
      path: ":profile_slug/playlists/:playlist_slug"
    }, function () {
      this.route("show", {
        path: "/"
      });
      this.route("edit");
    });
    this.route("merch", {
      path: ":profile_slug/:merch_slug"
    }, function () {
      this.route("show", {
        path: "/"
      });
      this.route("edit");
    });
    this.route("resource", {
      path: ":profile_slug/:resource_slug"
    }, function () {
      // Important! - Here put all the common routes under release, track and merch.
      this.route("show");
      this.route("edit");
    });
    this.route("releases", function () {
      this.route("tagged", {
        path: ":tag_slug"
      });
    }); // Keep the static routes at the end to prevent accidental profile slug clash.

    this.route("claim");
    this.route("labels");
    this.route("artists");
    this.route("mixes");
    this.route("playlists");
    this.route("dashboard", function () {
      this.route("tracks");
      this.route("mixes");
      this.route("releases");
      this.route("stats");
    });
    this.route("purchases");
    this.route("feed", function () {
      this.route("explore", {
        path: "/"
      });
      this.route("likes");
      this.route("following");
    });
    this.route("checkout", function () {
      this.route("info");
      this.route("payment");
    });
    this.route("login");
    this.route("signup");
    this.route("create-new-playlist");
    this.route("password-reset");
    this.route("upload", function () {
      this.route("track");
      this.route("mix");
      this.route("followers");
    });
    this.route("apply");
    this.route("play-sessions");
    this.route("analytics", function () {
      this.route("play-sessions");
      this.route("sales");
      this.route("sale", {
        path: "sales/:purchase_id"
      }, function () {
        this.route("edit", {
          path: "/item/:item_id/edit"
        });
      });
      this.route("payouts");
    });
    this.route("edit", function () {
      this.route("shipping");
      this.route("profile");
      this.route("notifications");
      this.route("user");
    });
    this.route("search");
    this.route("welcome");
    this.route("izvir");
    this.route("static-page", {
      path: "*path"
    });
  });
  var _default = Router;
  _exports.default = _default;
});