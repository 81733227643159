define("formaviva-web/_pods/components/app-wide-modals/unsaved-changes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    unsavedChangesModal: Ember.inject.service("unsaved-changes-modal"),
    actions: {
      confirmLeave: function confirmLeave() {
        this.get("unsavedChangesModal").confirmLeave();
      },
      cancelLeave: function cancelLeave() {
        this.get("unsavedChangesModal").cancelLeave();
      }
    }
  });

  _exports.default = _default;
});