define("formaviva-web/mixins/profile-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    fetchFollowingProfiles: function fetchFollowingProfiles(profileSlug) {
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "-followed_at";
      var filterByType = arguments.length > 3 ? arguments[3] : undefined;
      return this.store.query("profile", {
        page: page,
        sort: sort,
        per_page: 20,
        engagements: true,
        filter: {
          slug: profileSlug,
          filter_by_type: filterByType,
          engagement_type: "following"
        }
      });
    },
    fetchLikedTracks: function fetchLikedTracks(profileSlug, page) {
      var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "-liked_at";
      var filterByType = arguments.length > 3 ? arguments[3] : undefined;
      return this.store.query("track", {
        page: page,
        sort: sort,
        per_page: 20,
        engagements: true,
        filter: {
          profile_slug: profileSlug,
          filter_by_type: filterByType
        }
      });
    },
    fetchReleases: function fetchReleases(profileSlug, page) {
      return this.store.query("release", {
        per_page: 20,
        page: page,
        public: true,
        sort: "-release_date",
        filter: {
          profile_slug: profileSlug
        }
      });
    },
    fetchMixes: function fetchMixes(profileSlug, page) {
      return this.store.query("track", {
        page: page,
        public: true,
        per_page: 20,
        sort: "-created_at",
        filter: {
          profile_slug: profileSlug,
          mix: true
        }
      });
    },
    fetchFollowers: function fetchFollowers(profileSlug) {
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "-followed_at";
      var filterByType = arguments.length > 3 ? arguments[3] : undefined;
      return this.store.query("profile", {
        page: page,
        sort: sort,
        per_page: 20,
        engagements: true,
        filter: {
          slug: profileSlug,
          filter_by_type: filterByType,
          engagement_type: "follower"
        }
      });
    },
    fetchPlaylists: function fetchPlaylists(profileSlug) {
      var isCurrentUserPlaylist = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      var per_page = 18;

      if (isCurrentUserPlaylist) {
        // all user playlists
        return this.store.query("playlist", {
          page: page,
          per_page: per_page,
          filter: {
            slug: profileSlug
          }
        });
      } else {
        // public user playlists
        return this.store.query("playlist", {
          page: page,
          per_page: per_page,
          public_playlists: true,
          find_by_slug: true,
          profile_slug: profileSlug
        });
      }
    }
  });

  _exports.default = _default;
});