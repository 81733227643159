define("formaviva-web/_pods/releases/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ["page", "sort", {
      filterByItemType: "filter[item_type]",
      filterByTag: "filter[tag]"
    }],
    filterByItemType: null,
    filterByTag: null,
    page: 1,
    sort: null,
    availableSortOptions: null,
    availableFilters: null,
    availableFilterOptions: null,
    tags: Ember.computed(function () {
      return this.store.findAll("tag");
    }),
    sortedTags: Ember.computed.sort("tags", function (a, b) {
      var countDifference = b.get("releaseCount") - a.get("releaseCount"); // Sort by release count first

      if (countDifference == 0) {
        // Untie release count alphabetically
        if (b.get("name") < a.get("name")) {
          return 1;
        } else {
          return -1;
        }
      }

      return countDifference;
    }),
    allTag: Ember.computed(function () {
      return Ember.Object.create({
        id: -1,
        name: "All",
        slug: ""
      });
    }),
    // prettier-ignore
    selectedSortOption: Ember.computed("sort", {
      // eslint-disable-line ember/require-computed-property-dependencies
      get: function get() {
        if (this._selectedSortOption) return this._selectedSortOption;
        var sort = this.sort;
        var sortOption = {
          label: "Release date (Newest)",
          field: "release_date",
          direction: "-",
          icon: "angle-up"
        };

        if (sort) {
          if (sort.includes("name")) {
            sortOption.label = "Name (A-Z)";
            sortOption.direction = "-";
            sortOption.field = "name";

            if (!sort.includes("-")) {
              sortOption.label = "Name (Z-A)";
              sortOption.direction = "";
            }
          } else if (sort.includes("FV")) {
            sortOption.label = "Newest on Formaviva";
            sortOption.direction = "-";
            sortOption.field = "published_at";

            if (!sort.includes("-")) {
              sortOption.label = "Oldest on Formaviva";
              sortOption.direction = "";
            }
          } else {
            if (!sort.includes("-")) {
              sortOption.label = "Release date (Oldest)";
              sortOption.direction = "";
            }
          }
        }

        return sortOption;
      },
      set: function set(key, value) {
        return this._selectedSortOption = value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var sortOptions = [{
        label: "Release date (Newest)",
        field: "release_date",
        direction: "-"
      }, {
        label: "Release date (Oldest)",
        field: "release_date",
        direction: ""
      }, {
        label: "Name (A-Z)",
        field: "name",
        direction: ""
      }, {
        label: "Name (Z-A)",
        field: "name",
        direction: "-"
      }, {
        label: "Newest on Formaviva",
        field: "published_at",
        direction: "-"
      }, {
        label: "Oldest on Formaviva",
        field: "published_at",
        direction: ""
      }];
      this.set("availableSortOptions", sortOptions);
      var filters = ["itemType"];
      this.set("availableFilters", filters);
      var filterOptions = {
        itemType: {
          options: [{
            label: "Digital and vinyl",
            field: "all"
          }, {
            label: "Only digital",
            field: "digital"
          }, {
            label: "Only vinyl",
            field: "physical"
          }],
          selectedOptionName: "filterByItemType",
          defaultOption: {
            label: "Digital and vinyl",
            field: "all"
          }
        }
      };
      this.set("availableFilterOptions", filterOptions);
    },
    actions: {
      setTag: function setTag(filterValue) {
        this.router.transitionTo("releases", {
          queryParams: {
            "filter[tag]": filterValue.slug
          }
        });
      },
      setSelectedTag: function setSelectedTag(tag) {
        this.set("selectedTag", tag);
      }
    }
  });

  _exports.default = _default;
});