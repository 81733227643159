define("formaviva-web/mixins/playlist-actions", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === "test";

  var _default = Ember.Mixin.create({
    actions: {
      savePlaylist: function savePlaylist(playlist) {
        var _this = this;

        this.set("isSaving", true);
        playlist.save().then(function () {
          _this.get("notifications").success("Playlist saved successfully.", {
            autoClear: true
          });

          if (!isTest && window.history.length) {
            window.history.back();
          } else {
            _this.transitionToRoute("playlist.show", playlist.get("profile.slug"), playlist.get("slug"));
          }
        }).catch(function () {
          _this.get("notifications").error("There were some errors while saving the playlist. Please check if all tracks include at least one artist.", {
            autoClear: true
          });
        }).finally(function () {
          _this.set("isSaving", false);
        });
      },
      deletePlaylist: function deletePlaylist(playlist) {
        var _this2 = this;

        this.set("isDeleting", true);
        var promise = playlist.destroyRecord();
        promise.then(function () {
          _this2.get("notifications").success("Playlist deleted successfully.", {
            autoClear: true
          });

          _this2.transitionToRoute("dashboard");
        });
        promise.catch(function () {
          _this2.get("notifications").error("There were some errors while deleting the playlist.", {
            autoClear: true
          });
        });
        promise.finally(function () {
          return _this2.set("isDeleting", false);
        });
      }
    }
  });

  _exports.default = _default;
});