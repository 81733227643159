define("formaviva-web/models/profile", ["exports", "ember-data", "formaviva-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    recordType: "profile",
    name: attr("string"),
    slug: attr("string"),
    firstName: attr("string"),
    lastName: attr("string"),
    paypalPayoutAddress: attr("string"),
    secondaryEmail: attr("string"),
    vatNumber: attr("string"),
    bio: attr("string"),
    city: attr("string"),
    state: attr("string"),
    country: attr("string"),
    profileType: attr("string"),
    imageUploadId: attr("string"),
    imageUrl: attr("string", {
      readOnly: true
    }),
    playlists: hasMany("playlist"),
    shippingOrigins: hasMany("shipping-origin"),
    adjustedImageUrl: Ember.computed("imageUrl", function () {
      if (this.get("imageUrl") && this.get("imageUrl")[0] === "/") {
        return "".concat(_environment.default.serverURL, "/").concat(this.get("imageUrl"));
      } else {
        return this.get("imageUrl");
      }
    }),
    canonicalUrl: Ember.computed("slug", function () {
      return "".concat(_environment.default.webURL, "/").concat(this.get("slug"));
    }),
    isLabel: Ember.computed.equal("profileType", "label"),
    isArtist: Ember.computed.equal("profileType", "artist"),
    isListener: Ember.computed.equal("profileType", "listener"),
    followed: attr("boolean"),
    followersCount: attr("number"),
    followedProfilesCount: attr("number"),
    playlistsCount: attr("number"),
    publicPlaylistsCount: attr("number"),
    approved: attr("boolean"),
    claimed: attr("boolean"),
    associatedReleasesCount: attr("number"),
    associatedTracksCount: attr("number"),
    associatedMixesCount: attr("number"),
    likesCount: attr("number"),
    playSessionsCount: attr("number"),
    facebook: attr("string"),
    bandcamp: attr("string"),
    discogs: attr("string"),
    instagram: attr("string"),
    website: attr("string"),
    residentAdvisor: attr("string"),
    soundcloud: attr("string")
  });

  _exports.default = _default;
});