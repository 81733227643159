define("formaviva-web/services/app-wide-modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    isDeleting: false,
    isSuccessful: false,
    showDeleteAlertModal: false,
    showAddToPlaylistModal: false,
    toggleLoginModal: function toggleLoginModal() {
      var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var pendingAction = arguments.length > 1 ? arguments[1] : undefined;
      var props = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (show) {
        this.setProperties({
          pendingAction: pendingAction,
          subheader: props.subheader,
          header: props.header
        });
      } else {
        // Execute pending action (e.g. track like)
        var _pendingAction = this.pendingAction;

        if (_pendingAction) {
          var _pendingAction2 = _slicedToArray(_pendingAction, 3),
              fn = _pendingAction2[0],
              arg = _pendingAction2[1],
              isTask = _pendingAction2[2];

          if (isTask) fn.perform(arg);else fn(arg);
        }

        this.resetProps();
      }

      this.set("loginModalShown", show);
    },
    resetProps: function resetProps() {
      this.setProperties({
        pendingAction: null,
        header: null
      });
    }
  });

  _exports.default = _default;
});