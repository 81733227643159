define("formaviva-web/_pods/playlist/edit/route", ["exports", "formaviva-web/mixins/unsaved-record-notification", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _unsavedRecordNotification, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedRecordNotification.default, {
    pageTitle: "Edit Playlist | Formaviva",
    model: function model() {
      var _this$paramsFor = this.paramsFor("playlist"),
          playlist_slug = _this$paramsFor.playlist_slug;

      return this.store.queryRecord("playlist", {
        slug: playlist_slug,
        find_by_slug: true
      });
    }
  });

  _exports.default = _default;
});