define("formaviva-web/services/unsaved-changes-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    unsavedChangesModalShown: false,
    transition: null,
    record: null,
    showUnsavedChangesModal: function showUnsavedChangesModal(transition, record) {
      this.setProperties({
        unsavedModalShown: true,
        transition: transition,
        record: record
      });
    },
    isUnsavedModalVisible: function isUnsavedModalVisible() {
      var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.set("unsavedModalShown", show);
    },
    confirmLeave: function confirmLeave() {
      var record = this.record,
          transition = this.transition;

      if (record.get("isNew")) {
        record.destroyRecord();
      } else {
        record.rollbackAttributes();
      }

      this.isUnsavedModalVisible(false);
      transition.retry();
    },
    cancelLeave: function cancelLeave() {
      this.isUnsavedModalVisible(false);
      this.transition.abort();
    }
  });

  _exports.default = _default;
});