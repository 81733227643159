define("formaviva-web/_pods/components/action-btns/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    session: Ember.inject.service(),
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    trackActions: Ember.inject.service("track/track-actions"),
    playlistActions: Ember.inject.service("playlist/playlist-actions"),
    appWideModals: Ember.inject.service(),
    downloadOptions: Object.freeze(["mp3", "wav", "flac", "aiff"]),
    actions: {
      likeTrack: function likeTrack(track) {
        this.trackActions.likeTrack(track);
      },
      openAddTrackToPlaylistModal: function openAddTrackToPlaylistModal(track) {
        if (!this.session.data.authenticated.access_token) {
          this.appWideModals.set("loginModalShown", true);
        } else {
          this.playlistActions.set("selectedTrack", track);
          this.appWideModals.set("showAddToPlaylistModal", true);
        }
      },
      addToCart: function addToCart(track, release) {
        var _this = this;

        if (track) {
          if (track.mix) this.shoppingCart.addMixToCart(track);else this.shoppingCart.addTrackToCart(track, release);
        } else {
          // need to reload release to get all details otherwise addReleaseToCart/addMerchToCart is not working
          release.reload().then(function (release) {
            switch (release.recordType) {
              case "merch":
                _this.shoppingCart.addMerchToCart(release);

                break;

              case "release":
                _this.shoppingCart.addReleaseToCart(release);

                break;
            }
          });
        }
      },
      toggleSharePopup: function toggleSharePopup() {
        this.toggleProperty("sharePopupVisible");
      }
    }
  });

  _exports.default = _default;
});