define("formaviva-web/_pods/playlist/edit/controller", ["exports", "formaviva-web/mixins/playlist-actions"], function (_exports, _playlistActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_playlistActions.default, {
    currentSession: Ember.inject.service(),
    playlist: Ember.computed.alias("model"),
    appWideModals: Ember.inject.service(),
    playlistActions: Ember.inject.service("playlist/playlist-actions"),
    disableUpdateButton: Ember.computed("playlist", "playlist.name", function () {
      return !this.playlist || Ember.isBlank(this.playlist.name);
    }),
    actions: {
      toggleDeleteAlert: function toggleDeleteAlert() {
        this.get("appWideModals").set("showDeleteAlertModal", true);
      },
      triggerUpdatePlaylist: function triggerUpdatePlaylist(playlist) {
        this.playlistActions.updatePlaylist(playlist);
      }
    }
  });

  _exports.default = _default;
});