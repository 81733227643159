define("formaviva-web/_pods/components/playlist/playlists-modal-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EQEwZzVJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"isTrackInPlaylist\"]],\"playlist-modal-row-container active-row\",\"playlist-modal-row-container\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row-index\"],[9],[1,[29,\"add\",[[25,[\"index\"]],1],null],false],[0,\" .\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row-name\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"playlist-img\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"playlist\",\"attributes\",\"first_track_image_url\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\"],[12,\"src\",[30,[[25,[\"playlist\",\"attributes\",\"first_track_image_url\"]]]]],[11,\"alt\",\"playlist image\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[29,\"fa-icon\",[\"music\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n        \"],[1,[25,[\"playlist\",\"attributes\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"button\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"isTrackInPlaylist\"]],\"btn-action btn-check-success\",\"btn-action btn-plus\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isTrackInPlaylist\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"fa-icon\",[\"check\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[29,\"fa-icon\",[\"plus\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"triggerAddTrackToPlaylist\",[25,[\"playlist\",\"id\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/playlist/playlists-modal-row/template.hbs"
    }
  });

  _exports.default = _default;
});