define("formaviva-web/_pods/create-new-playlist/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      track_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params.track_id) {
        return this.store.findRecord("track", params.track_id);
      } else {
        return null;
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model) {
        controller.set("track", model);

        if (model.get("name")) {
          controller.set("playlistName", model.get("name"));
        }
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          track: null,
          playlistName: "",
          privatePlaylist: false
        });
      }
    }
  });

  _exports.default = _default;
});