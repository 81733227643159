define("formaviva-web/mixins/track-download", ["exports", "moment", "formaviva-web/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    messageBus: Ember.inject.service(),
    store: Ember.inject.service(),
    messages: Ember.inject.service(),
    authenticatedAjax: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    appWideModals: Ember.inject.service(),
    cable: Ember.inject.service(),
    downloadOptions: null,
    selectedTrack: null,
    downloadPopupVisible: false,
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.subscription) this.subscription.unsubscribe();
    },
    showDownloadButton: Ember.computed("track.{purchased,mix,price,private,allowDownloadInPrivateMode,allowFreeDownloadWithSecret,privateToken}", "release.purchased", function () {
      var track = this.track;
      var release = this.release;
      var isTrackPurchased = this.track && this.track.purchased;
      var isTrackFreeWithSecret = this.track.allowFreeDownloadWithSecret && this.track.privateToken;
      var isReleasePurchased = this.release && this.release.purchased;

      if (isTrackPurchased || isReleasePurchased || isTrackFreeWithSecret) {
        return true;
      } else if (track) {
        if (track.purchased) return true;else if (track.mix && track.price === 0) return true;else return track.private && track.allowDownloadInPrivateMode;
      } else return release && release.purchased;
    }),
    showDownloadingIndicator: Ember.computed.reads("isDownloading"),
    downloadDisabledReason: Ember.computed("release", "release.private", function () {
      if (Ember.isPresent(this.release) && !this.release.private) {
        var releaseDate = this.release.get("releaseDate");

        if ((0, _moment.default)().isBefore((0, _moment.default)(releaseDate))) {
          var formattedReleaseDate = (0, _moment.default)(releaseDate).format("DD MMM YYYY");
          return "Track will be available for download from ".concat(formattedReleaseDate);
        }
      }

      return "";
    }),
    isDownloadDisabled: Ember.computed("downloadDisabledReason", function () {
      return Ember.isPresent(this.downloadDisabledReason);
    }),
    handleReceivedData: function handleReceivedData(data) {
      var _this = this;

      var trackDownloadingStatus = data.downloading_status;

      if (trackDownloadingStatus === "track_transcoded") {
        var completedFiles = data.complete;
        var totalFiles = data.total;
        this.notifications.clearAll();
        this.notifications.info("Completed processing ".concat(completedFiles, " of ").concat(totalFiles, "."));
      } else if (trackDownloadingStatus === "compress") {
        this.notifications.clearAll();
        this.notifications.info("Compressing your processed files.");
      } else if (trackDownloadingStatus === "complete") {
        this.notifications.success("File downloaded successfully.", {
          autoClear: true,
          clearDuration: 5000
        });

        if (data.download_url) {
          window.location = data.download_url;
        }

        Ember.run.later(function () {
          _this.set("isDownloading", false);

          _this.notifications.clearAll();
        }, 3000);
      } else if (trackDownloadingStatus === "failed") {
        this.set("isDownloading", false);
        this.notifications.clearAll();
        this.notifications.error("Something went wrong with track download. Try again or try another file format.");
      }
    },
    setupConsumer: function setupConsumer(channelParams) {
      var _this2 = this;

      var consumer = this.cable.createConsumer(_environment.default.webSocketURL);
      if (this.subscription) this.subscription.unsubscribe();

      var didReceiveMessage = function didReceiveMessage(data) {
        _this2.handleReceivedData(data);
      };

      var subscription = consumer.subscriptions.create(channelParams, {
        received: didReceiveMessage
      });
      this.set("subscription", subscription);
    },
    downloadTrack: function downloadTrack(_ref) {
      var _this3 = this;

      var track = _ref.track,
          channelSubscriptionParams = _ref.channelSubscriptionParams,
          downloadParams = _ref.downloadParams;

      if (!this.currentSession.isAuthenticated) {
        // On login/signup, pending action will be triggered
        var pendingAction = [this.downloadTrack.bind(this), {
          track: track,
          channelSubscriptionParams: channelSubscriptionParams,
          downloadParams: downloadParams
        }];
        return this.appWideModals.toggleLoginModal(true, pendingAction);
      }

      this.set("selectedTrack", track);
      this.set("isDownloading", true);
      this.send("toggleDownloadPopup");
      var url = downloadParams.url,
          data = downloadParams.data;
      if (Ember.isPresent(channelSubscriptionParams)) this.setupConsumer(channelSubscriptionParams);
      this.get("authenticatedAjax").post(url, {
        data: data
      }).then(function () {
        _this3.notifications.info(_this3.messages.downloadInitiatedMessage);
      }).catch(function () {
        _this3.notifications.error(_this3.messages.downloadInitiationFailedError);
      });
    },
    actions: {
      toggleDownloadPopup: function toggleDownloadPopup() {
        if (!this.downloadDisabledReason) this.toggleProperty("downloadPopupVisible");
      },
      downloadPurchasedTrack: function downloadPurchasedTrack(purchaseItem, track, format) {
        var downloadList = [];
        downloadList.push(track.get("id"));
        var purchaseId = purchaseItem.get("purchaseId");
        this.send("downloadSelectedTracks", purchaseId, downloadList, format, "single");
      },
      downloadFreeTrack: function downloadFreeTrack(track, format) {
        var url = "".concat(_environment.default.apiBaseURL, "/tracks/").concat(track.get("id"), "/download");
        var data = {
          format: format,
          private_token: this.track.get("privateToken")
        };
        var downloadParams = {
          url: url,
          data: data
        };
        var channelSubscriptionParams = {
          channel: "TrackChannel",
          track_id: this.track.get("id")
        };
        this.downloadTrack({
          track: track,
          channelSubscriptionParams: channelSubscriptionParams,
          downloadParams: downloadParams
        });
      },
      downloadSelectedTracks: function downloadSelectedTracks(purchaseId, downloadList, format) {
        var downloadType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "multiple";
        if (Ember.isBlank(purchaseId)) return;
        var url = "".concat(_environment.default.apiBaseURL, "/purchases/").concat(purchaseId, "/download");
        var data = {
          format: format,
          download_type: downloadType,
          downloadable_type: "item",
          download_list: JSON.stringify(downloadList)
        };
        var downloadParams = {
          url: url,
          data: data
        };
        this.setupConsumer({
          channel: "PurchaseChannel",
          purchase_id: purchaseId
        });
        this.downloadTrack({
          channelSubscriptionParams: null,
          downloadParams: downloadParams
        });
      }
    }
  });

  _exports.default = _default;
});