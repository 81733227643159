define("formaviva-web/_pods/components/app-wide-modals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQ7CdLVM",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Add all app wide modals here\"],[0,\"\\n\"],[1,[23,\"app-wide-modals/login\"],false],[0,\"\\n\"],[1,[23,\"app-wide-modals/add-to-playlist\"],false],[0,\"\\n\"],[1,[23,\"app-wide-modals/unsaved-changes\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/app-wide-modals/template.hbs"
    }
  });

  _exports.default = _default;
});