define("formaviva-web/_pods/profile/playlists/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JbMO0Rrt",
    "block": "{\"symbols\":[\"playlist\"],\"statements\":[[4,\"if\",[[25,[\"isCurrentUserPlaylist\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-variant-1\"],[9],[0,\"\\n        \"],[1,[29,\"fa-icon\",[\"plus\"],null],false],[0,\" Create New Playlist\\n      \"],[3,\"action\",[[24,0,[]],\"transitionToNewPlaylist\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"playlists\"]],\"profile.playlists\",[25,[\"page\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"css-loader css-loader--inline\"],[9],[0,\"\\n    \"],[1,[23,\"loader-line-scale-pulse-out\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fv-box-list fv-box-list--six\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"playlists\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fv-box\",null,[[\"playlist\",\"truncateTitle\",\"class\"],[[24,1,[]],true,\"fv-box-list__item\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"fv-box-list__placeholder\"],[9],[0,\"This profile hasn't published any playlists yet.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"playlists\"]],\"profile.playlists\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/profile/playlists/template.hbs"
    }
  });

  _exports.default = _default;
});