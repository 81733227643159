define("formaviva-web/mixins/unsaved-record-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    unsavedChangesModal: Ember.inject.service("unsaved-changes-modal"),
    actions: {
      willTransition: function willTransition(transition) {
        var record = this.currentModel;
        var isNew = record.isNew;
        var unsaved;

        if (isNew) {
          var ignoreFields = ["uuid", "privateToken", "remixerIds", "price", "mix", "slug", "displayName"];
          var changedAttributes = record.changedAttributes();
          var changedAttributesKeys = Object.keys(changedAttributes);
          unsaved = changedAttributesKeys.filter(function (k) {
            return !ignoreFields.includes(k);
          }).some(function (k) {
            return !!changedAttributes[k][1];
          }); // [1] holds the new value
        } else {
          unsaved = record.get("hasDirtyAttributes");
        }

        if (unsaved) {
          transition.abort();
          this.get("unsavedChangesModal").showUnsavedChangesModal(transition, record);
        } else {
          if (isNew) {
            record.destroyRecord();
          }
        }
      }
    }
  });

  _exports.default = _default;
});