define("formaviva-web/_pods/playlists/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    queryParams: ["page", "sort", {
      filterByTag: "filter[tag]"
    }],
    page: 1,
    filterByTag: null,
    actions: {
      transitionToNewPlaylist: function transitionToNewPlaylist() {
        this.transitionToRoute("create-new-playlist", {
          queryParams: {
            track_id: null
          }
        });
      }
    }
  });

  _exports.default = _default;
});