define("formaviva-web/services/persistent-player-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    messageBus: Ember.inject.service(),
    store: Ember.inject.service(),
    localPersistence: Ember.inject.service(),
    showMasterPlayer: Ember.computed("currentTrack.id", function () {
      return this.currentTrack && this.currentTrack.id !== "dummy";
    }),
    isPlayingTrack: false,
    init: function init() {
      // We set a dummy track so that the player renders and the player
      // object becomes available immediately. This is important
      // for clicks on play in slave players, so they can initially call the play
      // directly on the audio object. It's important to initially call play
      // on the object directly for the first time, otherwise we get a
      // "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission"
      // error. Otherwise, the play event is sent asynchronously to master player
      // and browser (Safari currently) treats this as a website that autoplays music
      // and prevents it.
      this._super.apply(this, arguments);

      var dummyTrack = {
        id: "dummy"
      };
      this.set("currentTrack", dummyTrack);
      this.setupLocalStorageListeners();
    },
    setCurrentPlayingTrack: function setCurrentPlayingTrack(track) {
      // trackSignature holds three pieces of info: track id, release id, and tabs's application id.
      var trackSignature = {
        trackId: track.get("id"),
        releaseId: track.get("releasePlayedFrom") ? track.get("releasePlayedFrom.id") : null,
        appInstance: window.__fvAppInstance
      };
      this.localPersistence.saveLastPlayedTrack(trackSignature);
    },
    setRecentlyPlayedTrack: function setRecentlyPlayedTrack() {
      var _this = this;

      if (!window.localStorage) {
        return;
      }

      var recentlyPlayedTrackData = this.localPersistence.getLastPlayedTrack();
      var trackId = recentlyPlayedTrackData.trackId,
          releaseId = recentlyPlayedTrackData.releaseId;
      if (Ember.isBlank(trackId)) return;
      this.store.findRecord("track", trackId).then(function (track) {
        _this.set("currentTrack", track);

        if (releaseId) {
          _this.store.findRecord("release", releaseId).then(function (release) {
            track.set("releasePlayedFrom", release);
          });
        }
      }).catch(function (error) {
        if (window.Rollbar) Rollbar.error(error);

        if (error && error.errors) {
          var status = error.errors[0].status; // Currently both 404 (not found) and 403 (unauthorized)
          // must be handled as same because it includes explanation for both

          if (status && (status === "404" || "403")) {
            // catch and bubble here to prevent error message from throwing up
            return true;
          }
        }
      });
    },
    setupLocalStorageListeners: function setupLocalStorageListeners() {
      var _this2 = this;

      // The storage event fires when other tabs makes changes to localStorage.
      if (!window.addEventListener) {
        return;
      }

      window.addEventListener("storage", function (event) {
        if (event.key !== _this2.localPersistence.lastPlayedTrackDataStorageKey) {
          return;
        } // Check if new current playing track was set in a different window.


        var sourceAppInstance = JSON.parse(event.newValue).appInstance;
        var fromOtherTab = sourceAppInstance !== window.__fvAppInstance;

        if (!fromOtherTab) {
          return;
        } // If we reached this place, it means some other tab started playing music.
        // Therefore we must pause music coming from this window.


        _this2.get("messageBus").publish("playback:global:pause");
      });
    }
  });

  _exports.default = _default;
});