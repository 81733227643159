define("formaviva-web/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "formaviva-web/config/environment", "ember-inflector"], function (_exports, _emberData, _dataAdapterMixin, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service("session"),
    namespace: _environment.default.apiNamespace,
    host: _environment.default.serverURL,

    get headers() {
      var headers = {};

      if (this.session.isAuthenticated) {
        var accessToken = this.session.data.authenticated.access_token;
        var profileId = this.session.data.profileId;

        if (Ember.isPresent(accessToken)) {
          headers["Authorization"] = "Bearer ".concat(accessToken);
        }

        if (Ember.isPresent(profileId)) {
          headers["X-ProfileId"] = profileId;
        }
      }

      return headers;
    },

    pathForType: function pathForType(type) {
      var underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      // This is to include extra params with findRecord
      if (snapshot.adapterOptions && snapshot.adapterOptions.extra_params) {
        var url = this.buildURL(type.modelName, id, snapshot, "findRecord");
        var extraParams = snapshot.adapterOptions.extra_params;
        return this.ajax(url, "GET", {
          data: extraParams
        });
      } else {
        return this._super.apply(this, arguments);
      }
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var total = headers["total"] || headers["Total"];
      var perPage = headers["per-page"] || headers["Per-Page"];
      var pages = null;

      if (Ember.isPresent(total) && Ember.isPresent(perPage)) {
        pages = Math.ceil(total / perPage);
      }

      var meta = {
        total: total,
        perPage: perPage,
        pages: pages
      };
      if (!Ember.isPresent(payload)) payload = {};

      if (_typeof(payload) === "object") {
        payload.meta = meta;
      }

      return this._super(status, headers, payload, requestData);
    },
    urlForQuery: function urlForQuery(query) {
      if (query.public) {
        delete query.public;
        return "".concat(this._super.apply(this, arguments), "/public");
      } else if (query.search) {
        delete query.search;
        return "".concat(this._super.apply(this, arguments), "/search");
      } else if (query.engagements) {
        delete query.engagements;
        return "".concat(this._super.apply(this, arguments), "/engagements");
      } else if (query.homepage) {
        delete query.homepage;
        return "".concat(this._super.apply(this, arguments), "/homepage");
      } else if (query.liked_profiles) {
        var track_id = query.track_id;
        delete query.liked_profiles;
        delete query.track_id;
        return "".concat(this._super.apply(this, arguments), "/track_likes/liked_profiles/").concat(track_id);
      } else if (query.public_playlists) {
        delete query.public_playlists;
        return "".concat(this.host, "/").concat(this.namespace, "/public_playlists");
      } else {
        return this._super.apply(this, arguments);
      }
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var baseUrl = this.buildURL(modelName);

      if (query.public_playlists) {
        var playlist_slug = query.playlist_slug;
        delete query.public_playlists;
        delete query.playlist_slug;
        return "".concat(this.host, "/").concat(this.namespace, "/public_playlists/").concat(playlist_slug);
      } else if (query.find_by_slug) {
        return "".concat(baseUrl, "/").concat(encodeURIComponent(query.slug));
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});