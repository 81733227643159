define("formaviva-web/_pods/playlists/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Playlists | Formaviva",
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      filterByTag: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var page = params.page || 1;
      return this.store.query("playlist", {
        page: page,
        per_page: 24,
        filter: {
          public: true
        }
      });
    }
  });

  _exports.default = _default;
});