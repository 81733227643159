define("formaviva-web/_pods/components/app-wide-modals/delete-alert/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appWideModals: Ember.inject.service(),
    playlistActions: Ember.inject.service("playlist/playlist-actions"),
    router: Ember.inject.service(),
    actions: {
      closeDeleteAlert: function closeDeleteAlert() {
        this.get("appWideModals").set("showDeleteAlertModal", false);
      },
      triggerDeleteModel: function triggerDeleteModel(model) {
        if (model.constructor.modelName === "playlist") {
          this.get("playlistActions").deletePlaylist(model);
        }
      },
      closeAndRedirect: function closeAndRedirect() {
        this.get("router").transitionTo("playlists");
        this.get("appWideModals").set("showDeleteAlertModal", false);
      }
    }
  });

  _exports.default = _default;
});