define("formaviva-web/_pods/components/playlist/playlists-modal-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedPlaylist: false,
    appWideModals: Ember.inject.service(),
    playlistActions: Ember.inject.service("playlist/playlist-actions"),
    isTrackInPlaylist: Ember.computed.or("selectedPlaylist", "playlistActions.trackInPlaylist"),
    actions: {
      triggerAddTrackToPlaylist: function triggerAddTrackToPlaylist(playlist_id) {
        this.set("selectedPlaylist", true);
        this.playlistActions.addTrackToPlaylist(playlist_id);
      }
    }
  });

  _exports.default = _default;
});