define("formaviva-web/_pods/components/app-wide-modals/delete-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MzVBfwN2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"appWideModals\",\"showDeleteAlertModal\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\",\"animatable\",\"onClose\",\"clickOutsideToClose\",\"containerClass\"],[true,true,\"closeDeleteAlert\",true,\"fv-modal fv-modal--xs\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-end\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"btn-close png-icon\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fv fv-cancel\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],\"closeDeleteAlert\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"appWideModals\",\"isDeleting\"]]],null,{\"statements\":[[0,\"        Deleting ...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"appWideModals\",\"isSuccessful\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\"Deleted successfully\"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-variant-1\"],[11,\"type\",\"button\"],[9],[0,\"OK\"],[3,\"action\",[[24,0,[]],\"closeAndRedirect\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\"Are you sure you want to delete \"],[1,[25,[\"model\",\"name\"]],false],[0,\"?\"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"confirm-actions-buttons double-offset-top\"],[9],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"btn btn-secondary\"],[11,\"type\",\"button\"],[9],[0,\"No\"],[3,\"action\",[[24,0,[]],\"closeDeleteAlert\"]],[10],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"btn btn-danger\"],[11,\"type\",\"button\"],[9],[0,\"Yes\"],[3,\"action\",[[24,0,[]],\"triggerDeleteModel\",[25,[\"model\"]]]],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/app-wide-modals/delete-alert/template.hbs"
    }
  });

  _exports.default = _default;
});