define("formaviva-web/models/playlist", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    recordType: "playlist",
    name: attr("string"),
    description: attr("string"),
    public: attr("boolean"),
    slug: attr("string"),
    createdAt: attr("date"),
    updatedAt: attr("date"),
    firstTrackImageUrl: attr("string"),
    profileSlug: Ember.computed.alias("profile.slug"),
    profile: belongsTo("profile"),
    tracks: hasMany("track")
  });

  _exports.default = _default;
});