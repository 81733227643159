define("formaviva-web/_pods/components/tables/edit-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    store: Ember.inject.service(),
    playlistActions: Ember.inject.service("playlist/playlist-actions"),
    media: Ember.inject.service(),
    actions: {
      moveTrack: function moveTrack(track, direction) {
        var tracks = this.get("model.tracks").toArray();
        var fromIndex = tracks.indexOf(track);
        var toIndex = fromIndex + direction;
        tracks.splice(fromIndex, 1);
        tracks.splice(toIndex, 0, track);
        this.set("model.tracks", tracks);
        this.playlistActions.set("updatedTracks", tracks);
      },
      removeTrack: function removeTrack(track) {
        var tracks = this.get("model.tracks").toArray();
        var index = tracks.indexOf(track);
        tracks.splice(index, 1);
        this.set("model.tracks", tracks);
        this.playlistActions.set("updatedTracks", tracks);
      }
    }
  });

  _exports.default = _default;
});