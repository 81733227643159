define("formaviva-web/_pods/components/forms/playlist-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    playlist: Ember.computed.alias("model")
  });

  _exports.default = _default;
});